import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function WomenShoesPage() {
  return (
    <CategoryWrapper
      seoTitle="Sko"
      gender="dame"
      category="shoes"
    />
  )
}

export default WomenShoesPage
